<template>
  <div class="page" @click.stop="directoryOpen = false">
    <div class="Box flex-col align-center">
      <div class="directory-view flex-col">
        <div
          class="btn-view justify-center align-center"
          @click.stop="directoryOpen = !directoryOpen"
        >
          <img
            v-if="directoryOpen"
            class="img1"
            src="../../assets/icon/caret-open.png"
            alt=""
          />
          <img
            class="img2"
            v-else
            src="../../assets/icon/caret-close.png"
            alt=""
          />
          <span :class="{ active: directoryOpen ? true : false }">目录</span>
        </div>
        <div v-if="directoryOpen" class="flot-view flex-col">
          <span
            style="margin-top: 0"
            :class="{ active: activeIndex == 1 ? true : false }"
            @click.stop="activeOne"
            >为什么品牌很重要？</span
          >
          <span
            :class="{ active: activeIndex == 2 ? true : false }"
            @click.stop="activeTwo"
            >LED大屏广告的好处</span
          >
          <span
            :class="{ active: activeIndex == 3 ? true : false }"
            @click.stop="activeThree"
            >我们能做什么？</span
          >
        </div>
      </div>
      <header class="top-Img">
        <div class="title"></div>
      </header>
      <div class="titleTop" style="margin-top: 0" ref="scrollDiv1">
        <span class="leftIcon"></span>
        <span class="titleText">为什么品牌很重要？</span>
      </div>
      <div class="artical-text">
        <span class="vice-title">1. 差异化</span>
        <span class="txt"
          >不论企业/商店大小，在激烈的市场竞争中，强大的品牌有助于企业脱颖而出。品牌创造了独特的身份和个性，使其与众不同，并让客户印象深刻。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">2. 认可度和信任度</span>
        <span class="txt"
          >品牌可以在消费者中建立认可度和信任度。
          当人们看到熟悉的品牌时，他们通常会对其更有信心，因为他们将其与可靠性联系在一起。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">3. 客户忠诚度</span>
        <span class="txt"
          >强大的品牌培养客户忠诚度。
          当客户对品牌有积极的体验时，他们更有可能成为回头客并将该品牌推荐给他人。
          忠诚的客户群可以增加销售额并取得长期成功。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">4. 感知价值</span>
        <span class="txt"
          >品牌有助于在消费者心目中形成价值感知。
          通过品牌推广，公司可以将其产品或服务定位为品质或尊贵的象征，从而获得更大的盈利空间。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">5. 情感联系</span>
        <span class="txt"
          >品牌可以与目标受众建立情感联系。
          通过引起顾客的情感共鸣，品牌可以与消费者建立更深层次的精神认同，从而提高品牌忠诚度和拥护度。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">6. 扩展机会</span>
        <span class="txt"
          >强大的品牌为扩展新市场、推出新产品夯实基础。
          当一个品牌受到好评和信任时，推出新产品并让消费者接受它们就会变得更加容易。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">7. 员工敬业度</span>
        <span class="txt"
          >一个引人注目的品牌不仅能吸引顾客，还能吸引员工。
          强大的品牌标识和公司文化可以激励员工，培养他们对工作的自豪感、增强他们的归属感。工作不再仅仅是一份工作。</span
        >
        <span class="txt"
          >总的来说，品牌很重要，因为它塑造了客户、利益相关者和员工对企业的认同。
          它推动客户忠诚度、竞争优势和业务增长。
          从长远来看，投资建立和维护强大的品牌可以产生显著的收益。</span
        >
      </div>
      <div class="titleTop" ref="scrollDiv2">
        <span class="leftIcon"></span>
        <span class="titleText">LED大屏广告的好处</span>
      </div>
      <div class="artical-text">
        <span class="vice-title">1. 高可见度</span>
        <span class="txt"
          >LED屏幕以其亮度和清晰度著称，即使在明亮的户外环境中也能确保出色的可见度。
          他们可以展示充满活力和动态的内容，吸引注意力并从其他广告媒体中脱颖而出。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">2. 有影响力和吸引力</span>
        <span class="txt"
          >大尺寸户外 LED 屏幕可提供有影响力和身临其境的广告体验。
          他们可以展示高清视觉效果、视频、动画，甚至互动内容，吸引观众的注意力并给人留下难忘的印象。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">3. 内容的灵活性</span>
        <span class="txt"
          >LED屏幕在内容显示方面提供了极大的灵活性。
          广告商可以轻松更新和定制内容以适应不同的促销或活动。
          它们可以显示多种媒体格式，包括图像、视频、文本和实时信息，提供传达信息的多功能性。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">4. 有针对性</span>
        <span class="txt"
          >户外 LED
          屏幕能够显示动态内容和实时更新，使广告商能够向特定受众传递有针对性的信息。
          例如，他们可以在一天中的不同时间安排不同的广告，或者根据位置或受众人口统计数据定制内容。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">5. 提高品牌知名度</span>
        <span class="txt"
          >户外 LED 屏幕突出且具有视觉吸引力的特性有助于建立品牌知名度。
          醒目的展示吸引了路人的注意力，创造了强大的品牌形象，增强了品牌认知度和回想度。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">6. 实时更新</span>
        <span class="txt"
          >LED屏幕可以连接到中央控制系统，允许广告商远程实时更新内容。
          此功能对于经常更改其产品、促销或活动信息的企业特别有用，因为他们可以使用最新信息即时更新显示。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">7. 环保</span>
        <span class="txt"
          >LED 技术节能，与传统照明系统相比耗电量更少。 LED
          屏幕还产生更少的热量，减少能源浪费。 此外，LED
          屏幕通常采用可回收材料制成，使其更加环保。</span
        >
      </div>
      <div class="artical-text">
        <span class="vice-title">8. 增强客户体验</span>
        <span class="txt"
          >户外 LED 屏幕凭借其视觉上迷人的显示，增强了整体客户体验。
          他们在提供有用信息的同时娱乐和吸引观众，创造积极的品牌认知并提高客户满意度。
        </span>
      </div>
      <div class="titleTop" ref="scrollDiv3">
        <span class="leftIcon"></span>
        <span class="titleText">我们能做什么？</span>
      </div>
      <div class="artical-text">
        <span class="txt" style="margin-top: 0"
          >我们是一款专业的大屏广告自助投放软件，可为您提供一站式大屏投放解决方案。
        </span>
      </div>
      <div class="titleTop2">
        <span class="span1">>></span>
        <span class="span2">我们的产品包括2个端</span>
      </div>
      <div class="product flex-col align-center">
        <div class="product-item align-center">
          <div class="label"></div>
          <span>电脑官网：www.daping.cn</span>
        </div>
        <div class="product-item align-center">
          <div class="label"></div>
          <span>微信小程序：大屏时代极速版</span>
        </div>
        <div class="img">
          <img src="../../assets/img/miniprogram-img.jpg" alt="" />
        </div>
      </div>
      <div class="titleTop2">
        <span class="span1">>></span>
        <span class="span2">我们产品的亮点</span>
      </div>
      <span class="other-txt"
        >·
        指定时间：用户可以根据自己的需求，自主预约广告播出的时间。</span
      >
      <span class="other-txt"
        >·
        指定屏幕：用户可以根据自己的需求，自主选择将广告投放到哪块屏幕。</span
      >
      <span class="other-txt"
        >·
        在线下单：用户可以在线下单，全流程无需人工干预。订单审核通过后，系统会按照订单的指令自动播放广告视频。</span
      >
      <span class="other-txt"
        >·
        分享订单：用户可以将自己的订单分享给他人，收到分享的人打开分享页面同样能看到该订单广告投放时间表等信息，从而精确掌握每一次广告播出的时间。</span
      >
      <span class="other-txt"
        >·
        小程序投放：用户可以通过“大屏时代极速版”微信小程序进行广告投放，随时随地提交投放订单，操作简单、方便快捷。</span
      >
      <span class="other-txt"
        >·
        智能投放：用户仅需一次设置，系统可连续多天多次投放广告，且每次优先为用户选择高峰时段。</span
      >
      <span class="other-txt"
        >·
        大屏监控：同步现场大屏幕实时播放画面，用户可按时查看自己订单的播出情况。轻松实现远程监播、自主监播。</span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      directoryOpen: false,
      activeIndex: null,
      textList: [
        {
          title: "树立高端品牌形象",
          describe:
            "LED大屏广告因其表现力丰富，能最大程度地呈现出广告的视觉张力，体现出高端质感。 这一点，与商家对高端品牌形象的诉求是高度契合的。加之越来越多的高端品牌投放LED大屏广告，使得LED大屏广告的高端属性进一步巩固。",
        },
        {
          title: "冲击力大",
          describe:
            "LED广告屏幕尺寸大，画质绚丽，播放内容丰富，能够打造综合而丰富的感官刺激、给受众强大的视听冲击力，从而高效地传达信息。",
        },
        {
          title: "覆盖度广",
          describe:
            "因为LED大屏一般都位于城市的高端商业区或交通要道，周边人流密度大。商家可以通过投放LED大屏广告快速在目标商区、目标城市建立起品牌效应。加上LED大屏广告的高频播放，消费者对品牌的印象进一步加深、巩固。",
        },
        {
          title: "传达效率高",
          describe:
            "LED大屏广告可以零距离覆盖目标人群，助力商家将品牌信息一步到位传播至目标人群。",
        },
        {
          title: "强制观看",
          describe:
            "因为LED大屏广告具有极强的视听冲击力，加之其空间位置十分突出，且受众无法“遥控换台”，所以LED广告具有“不得不看”、“想不看都难”的性质。",
        },
        {
          title: "接纳度高",
          describe:
            "因其巧妙的利用了受众在公共场合的注意力空档期进行品牌信息传达，LED户外广告的反感率远低于电视广告、手机广告、网页广告，受众更易于接受。",
        },
        {
          title: "维护成本低",
          describe:
            "LED大屏广告的展示效果、播放内容可以随意切换，自由度极高，且制作好的广告内容可以反复使用、跨区域使用，不受时空限制。相较于传统人工安装画布，具有成本低、效率高、安全性高、灵活度高、复用度高等优势。",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 获取所有视频元素
      const videos = Array.from(document.getElementsByTagName("video"));
      // 为每个视频添加事件监听器
      videos.forEach((video) => {
        // 判断是否Safari浏览器
        if (
          /Safari/.test(navigator.userAgent) &&
          !/Chrome/.test(navigator.userAgent)
        ) {
          video.style = "object-fit: cover";
        } else {
          video.style = "object-fit: fill";
        }
        video.addEventListener("play", (event) => {
          // 暂停其他所有视频
          videos.forEach((otherVideo) => {
            if (otherVideo !== event.target) {
              otherVideo.pause();
            }
          });
        });
      });
    });
  },
  methods: {
    activeOne() {
      this.activeIndex = 1;
      const scrollElem = this.$refs.scrollDiv1;
      window.scrollTo({
        top: scrollElem.offsetTop - 100,
        behavior: "smooth",
      });
    },
    activeTwo() {
      this.activeIndex = 2;
      const scrollElem = this.$refs.scrollDiv2;
      window.scrollTo({
        top: scrollElem.offsetTop - 100,
        behavior: "smooth",
      });
    },
    activeThree() {
      this.activeIndex = 3;
      const scrollElem = this.$refs.scrollDiv3;
      window.scrollTo({
        top: scrollElem.offsetTop - 100,
        behavior: "smooth",
      });
    },
    goProductPrice(type) {
      this.$router.push({
        name: "ProductPrice",
        query: {
          active: type,
        },
      });
    },
    onClick_create() {
      this.$canvaUtil.onClickCreate();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  min-height: calc(100vh - 233rem);
  padding-top: 48rem;
  padding-bottom: 100rem;

  .Box {
    width: 1280rem;
    margin: 0 auto;
    background: #ffffff;
    padding-bottom: 50rem;
    .directory-view {
      position: fixed;
      top: 200rem;
      left: 1400rem;
      .btn-view {
        width: 78rem;
        height: 32rem;
        border-radius: 6rem;
        border: 1px solid #2bb3e3;
        cursor: pointer;
        .img1 {
          width: 12rem;
          height: 8rem;
          margin-right: 5rem;
        }
        .img2 {
          width: 8rem;
          height: 12rem;
          margin-right: 8rem;
        }
        span {
          font-size: 14rem;
          color: #757576;
          line-height: 20rem;
        }
        .active {
          color: #2d2d2d;
        }
      }
      .flot-view {
        padding: 12rem 18rem;
        background: #ffffff;
        box-shadow: 0px 2rem 4rem 0px rgba(0, 0, 0, 0.05);
        border-radius: 6rem;
        border: 1px solid #ebebeb;
        margin-top: 5rem;
        span {
          font-size: 14rem;
          color: #a7a7a7;
          line-height: 20rem;
          margin-top: 15rem;
          cursor: pointer;
          &:hover {
            color: #2bb3e3;
          }
        }
        .active {
          color: #575757;
        }
      }
    }
    .top-Img {
      width: 100%;
      height: 231rem;
      background: url("../../assets/img/launch-top-back.png");
      background-size: 100% 100%;
      display: flex;
      align-items: center;

      .title {
        width: 516rem;
        height: 65rem;
        background: url("../../assets/img/launch-top-text.png");
        background-size: 100% 100%;
        margin: 0 auto;
      }
    }
  }

  .titleTop {
    display: flex;
    align-items: center;
    margin-top: 60rem;
    margin-bottom: 4rem;
    .leftIcon {
      margin-right: 17rem;
      display: inline-block;
      width: 3rem;
      height: 18rem;
      background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
    }

    .titleText {
      width: 836rem;
      font-size: 20rem;
      color: #2d2d2d;
      line-height: 28rem;
    }
  }

  .artical-text {
    display: inline-block;
    width: 836rem;
    margin-top: 20rem;
    .vice-title {
      display: inline-block;
      font-size: 18rem;
      color: #4c4c4c;
      line-height: 25rem;
      margin-bottom: 3rem;
    }
    .txt {
      display: inline-block;
      font-size: 18rem;
      color: #989898;
      line-height: 25rem;
      margin-top: 12rem;
    }
  }

  .video-box-yh {
    width: 729rem;
    height: 443rem;
    background: url("../../assets/img/launch-yihua.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 50rem;
    margin-bottom: 30rem;

    .video {
      width: 282rem;
      height: 211rem;
      margin: 203rem 0rem 0 223rem;
    }
  }

  .video-box-sd {
    width: 729rem;
    height: 403rem;
    background: url("../../assets/img/shareVideo_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 50rem;
    margin-bottom: 30rem;

    .video {
      width: 306rem;
      height: 204rem;
      margin: 150rem 0rem 0 209rem;
    }
  }

  .video-box {
    width: 729rem;
    // height: 443rem;
    margin-top: 50rem;
    margin-bottom: 16rem;

    .dialog-title {
      position: absolute;
      width: 729rem;
      height: 32rem;
      background: #272727;
      text-align: center;
      background: rgba(39, 39, 39, 0.55);
      font-size: 14rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 32rem;
      border-radius: 15rem 15rem 0 0;
    }

    .video {
      width: 729rem;
      height: 443rem;
      border-radius: 15rem;
    }
  }

  .product {
    width: 728rem;
    background: #fbfbfb;
    background: url("../../assets/img/msgBack.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 20rem;
    padding: 10rem 0 30rem;
    margin-top: 22rem;
    .product-item {
      margin-top: 20rem;
      .label {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
      }
      span {
        font-size: 16rem;
        color: #6f6f6f;
        line-height: 22rem;
        letter-spacing: 1rem;
        margin-left: 9rem;
      }
    }
    .img {
      width: 128rem;
      height: 128rem;
      margin-top: 25rem;
      border-radius: 15rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .titleTop2 {
    width: 836rem;
    display: flex;
    align-items: center;
    margin-top: 34rem;
    margin-bottom: 12rem;
    span {
      font-size: 18rem;
      color: #4c4c4c;
      line-height: 25rem;
    }
    .span1 {
      color: #2bb3e3;
    }
    .span2 {
      margin-left: 6rem;
    }
  }
  .table-img-txt {
    margin-top: 8rem;
    font-size: 18rem;
    color: #4c4c4c;
    line-height: 25rem;
    span {
      color: #2bb3e3;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .table-img {
    width: 728rem;
    height: 395rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .txt-btn {
      font-size: 14rem;
      color: #76787D;
      position: absolute;
      bottom: 32rem;
      left: calc(50% - 25rem);
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        color: #2bb3e3;
      }
    }
  }
  .other-txt {
    display: inline-block;
    width: 836rem;
    font-size: 18rem;
    color: #969696;
    line-height: 25rem;
    margin-top: 12rem;
  }
  .serveUs {
    display: flex;
    align-items: center;
    margin-top: 24rem;
    span {
      font-size: 16rem;
      color: #252927;
      line-height: 22rem;
    }
    .span2 {
      color: #32c3f6;
    }
  }
}
</style>
